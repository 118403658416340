/*
 * COLORS:
 * Background: #292929
 * TDC light green: #A9DDB1
 * TDC green: #94CC9D
 * TDC dark green: #80BE8A
 * TDC dark purple: #6a64a2
 * TDC purple: #807bb9
 * TDC light purple: #bdaad2
 */

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

/*
 * Main
 */
html {
  position: relative;
  min-height: 93%;

}

body {
  background-color: #292929;
  font-family: "Source Sans Pro";
  margin-bottom: 200px;
  font-size: 18px;
}

a {
  color: #f38274;
  font-weight: bold;
  text-decoration: underline;
}

a:hover {
  color: #FFF;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h5 {
  color: #C25A4D;
  font-weight: bold;
}

.partners-logo img:hover {
  filter: invert(20%);
}

.social:hover {
  filter: invert(30%);
}

#index .first {
  min-height: 350px;
}

#index .second {
  min-height: 350px;
}

#index .second .border-bottom-right {
  border-radius: 0 0 150px 0;
  width: 90%;
}

#index .third {
  background: url('./media/img/bg.png');
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 18%;
  background-color: #6a64a2;
  /*border-bottom: 40px solid #807bb9;*/
}

#index .third .img {
  border-radius: 600px 0 0 0;
}

#index .fourth {
  background: linear-gradient(to bottom, #6a64a2 20%, #807bb9 20%, #807bb9 35%, #bdaad2 35%, #bdaad2 50%, #292929 50%, #292929 60%);
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #bdaad2;
}

#index .info-border {
  background: linear-gradient(to bottom, #292929 50%, #6a64a2 50%, #6a64a2 35%);
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #bdaad2;
}

#index .where {
  padding: 4px 4px 4px 6px;
  margin-bottom: 10px;
}

.where h5 {
  background-color: #A9DDB1;
  border-radius: 0 40px 0 0;
  margin: 0;
}

.where div {
  background-color: #363636;
  margin: 0;
  border-radius: 0 0 40px 40px;
  font-size: 26px;
}

.rounded div {
  margin: 0;
  background-color: #555;
  border-radius: 600px 0 0 0;
}

.img-info {
  border-radius: 0 500px 0 500px;
}

.jumbotron {
  border-radius: 0;
  background: url('./media/img/jumbotron/bg_jumbotron.jpg') no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  padding: 120px 0 120px 0;
  margin-bottom: 100px;
}

.members ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.members li {
  list-style: none;
  text-indent: -2.4em;
}

.members li:before {
  color: #f38274;
  content: "\2014";
  padding-right: 10px;
  padding-left: 5px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  line-height: 200px;
}

.footer-darkgreen {
  background-color: #80BE8A;
  height: 40px;
}

.footer-green {
  background-color: #94CC9D;
  height: 40px;
}

.footer-lightgreen {
  background-color: #A9DDB1;
}

/*
 * Navigation
 */
nav .navbar-nav li a{
  text-transform: uppercase;
}

.navbar-opacity {
  background-color: rgba(0, 0, 0, .85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

/*
 * Buttons
 */
.btn-success {
  font-size: 1.3em;
  border: 0px;
  border-radius: 40px;
  background: #A9DDB1;
  transition: all 0.3s ease 0s;
}

.btn-success:hover {
  background: #80BE8A;
}

.btn-primary {
  font-size: 1.3em;
  border: 0px;
  background: #A9DDB1;
  transition: all 0.3s ease 0s;
  margin: 3px 3px;
  padding: 0px 4px;
  color: #000;
}

.program-filter-button.selected {
    background: #6A63A0;
    color: #ffffff;
}

.btn-primary:hover {
  background: #88ba90;
}

.calendar-event a {
  text-decoration: none;
  color: #FFF;
}

.calendar-event {
  width:100%;
  background-color: #6A63A0;
}

.calendar-row {
    justify-content: center;
    margin: 0;
}

.favourite.calendar-event {
    background-color: #80BE8A;
    color: #292929;
}

.favourite.calendar-event a{
    color: #292929;
}

.favourite h4 a {
    color: #6a64a2;
}

.program-favorite-button {
    height: 100%;
}

.favourite button {
    color: #fff;
}

.program-favorite-button button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

/*
 * Extra utilities
 */
.flex-equal > * {
  -ms-flex: 1;
  flex: 1;
}

@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    flex: 1;
  }

  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .partner-img {
    width:60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .jumbotron {
    margin-bottom: 10px;
  }
  #index .third {
    background: none;
    background-color: #6a64a2;
  }
}

@media (max-width: 1366px) {
  #index .third {
    background: none;
    background-color: #6a64a2;
  }
}

.overflow-hidden { overflow: hidden; }

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

nav .navbar-nav li a{
  color: #FFF !important;
  text-decoration: none;
  font-size: 20px;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url('./media/img/icons/hamburger.svg');
}

.navbar-dark .navbar-toggler-icon.collapsed {
    background-image: url('./media/img/icons/hamburger_closed.svg');
}

.vimeo {
    height: 519px;
    width: 924px;
}
